import React from "react"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"

//Image

import pdfLogo from "../images/delinea-image-whitepaper-pam-maturity-model-thumbnail.jpg"
import CyberChecklistContact from "../components/CyberChecklistContact"
import WhitePaperPAMContact from "../components/WhitePaperPAMContact"

const PAMMaturityModel = () => {
  return (
    <Layout>
      <SEO
        title="Privileged Access Management Maturity Model"
        description="This step-by-step method for Privileged Access Management adoption helps you build a strong security foundation that supports you as you scale."
        keywords={["", "", ""]}
        lang="en-gb"
      />
      {/*********** Contact *************/}
      <div
        className="contact-section pdf-ebook-page"
        id="contact"
        // style={{ paddingTop: "120px" }}
      >
        <div className="outer-container" style={{ padding: "0" }}>
          {/* <h2>Contact Us</h2> */}
          <div className="pdf-hero cyber-insurance">
            <div className="blur-wrap">
              <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
                <div style={{ display: "flex" }} className="pdf-hero-wrap">
                  <div
                    style={{
                      display: "flex",
                      // background: "red",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={pdfLogo}
                      alt="delinea image tool cyber insurance checklist thumbnail"
                      style={{ width: "70%" }}
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h1
                      style={{
                        textAlign: "left",
                        color: "#FFFFFF",
                        marginBottom: "0em",
                      }}
                    >
                      Privileged Access Management <br /> Maturity Model
                    </h1>
                    <p
                      style={{
                        textAlign: "left",
                        color: "white",
                        fontSize: "x-large",
                      }}
                    >
                      Step-by-step roadmap for your PAM journey
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="row">
            <div
              className="inner-container-2"
              style={{
                justifyContent: "flex-start",
                marginTop: "28px",
                paddingLeft: "80px",
              }}
            >
              <div style={{ display: "flex" }}>
                {/* <img src={Bose} style={{ width: "35%" }}></img> */}
                <h3 style={{ textAlign: "left", textTransform: "none" }}>
                  The Delinea PAM Maturity Model is a framework to help you
                  systematically lower risk, increase business agility, and
                  improve operational efficiency.
                </h3>
              </div>
              <p style={{ textAlign: "left" }}>
                Understanding the stages of Privileged Access Management (PAM)
                maturity will help security and IT decision-makers prioritize
                activities and align budget and resources.
              </p>
              <p style={{ textAlign: "left" }}>
                Based on cybersecurity best practices and Metsi partner
                Delinea's work with over 10,000 customers of all types, read
                about the four phases that organisations progress through as
                they increase PAM expertise and adoption:
              </p>
              <ul
                style={{
                  width: "100%",
                  paddingBottom: "30px",
                  textAlign: "left",
                }}
                className="pdf-list"
              >
                <li>
                  <strong>Phase 0: High Risk</strong> – recognise risk and plan
                  for action.
                </li>
                <li>
                  <strong>Phase 1: Foundational</strong> – gain visibility over
                  attack surface and begin to reduce it.
                </li>
                <li>
                  <strong>Phase 2: Enhanced</strong> – expand PAM policies to
                  reduce overprivileged users.
                </li>
                <li>
                  <strong>Phase 3: Adaptive</strong> – increase automation and
                  intelligence for continuous improvement.
                </li>
              </ul>
              <p style={{ textAlign: "left" }}>
                In this whitepaper, you’ll learn the security controls and
                processes required to achieve each level of PAM maturity. This
                step-by-step method for PAM adoption helps you build a strong
                security foundation that supports you as you scale.
              </p>
              <p style={{ textAlign: "left" }}>
                Get your copy to assess your organisation’s current PAM maturity
                and navigate the next steps in your PAM journey.{" "}
              </p>
              {/* <i style={{ fontSize: "x-small", textAlign: "left" }}>
                *Items will be sent once a meeting is accepted, attended and you
                have provided a suitable shipping address. Please allow suitable
                time for shipping
              </i> */}
            </div>
            <div
              className="inner-container-2"
              style={{
                paddingRight: "80px",
              }}
            >
              <h3
                style={{
                  textAlign: "left",
                  textTransform: "none",
                  width: "100%",
                  paddingLeft: "1.25em",
                  marginTop: "28px",
                  paddingBottom: "0",
                }}
                id="checklist-prompt"
              >
                Get the checklist now.
              </h3>
              <WhitePaperPAMContact />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PAMMaturityModel
